$baseAssetWidth: 200;
$baseExpandedWidth: 302;
$baseAssetHeight: 440;
$baseAssetMargin: 10;
$marginBottomOfTitle: 16px;
$basePadding: 16px;

@value baseExpandedWidth #{$baseExpandedWidth};
@value baseAssetWidth #{$baseAssetWidth};

.asset {
  width: #{$baseAssetWidth}px;
  min-width: #{$baseAssetWidth}px;
  height: #{$baseAssetWidth}px;
  margin: #{$baseAssetMargin}px;
  background-position: top;
  background-size: #{$baseAssetWidth}px auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 16px;
  border: 2px solid #fff0;
  overflow: hidden;
  transition: background-size 250ms linear, height 250ms linear,
    width 250ms linear, min-width 250ms linear, border-color 250ms linear;
  box-shadow: var(--card-elevation);
  background-repeat: no-repeat;
  --text-default-color: var(--text-dark);

  > :last-child {
    background-color: white;
    height: 0px;
    transition: height 250ms linear, padding 250ms linear;
    padding: 0px $basePadding;
    display: flex;
    flex-direction: column;
    > * > :first-child {
      margin-bottom: $marginBottomOfTitle;
    }
    > :nth-child(2) {
      max-height: 24px * 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > :last-child {
      margin-top: auto;
    }
  }

  &.expanded {
    width: #{$baseExpandedWidth}px;
    height: #{$baseAssetHeight}px;
    background-size: #{$baseExpandedWidth}px auto;
    border-color: #e0e0e0;

    > :last-child {
      $maximumNumberOfLinesOfText: 4em;
      $minimumNumberOfLinesOfText: 3em;
      $paddingSum: $basePadding * 2;
      padding: $basePadding $basePadding;
      height: 148px;
    }
  }
  &.short {
    width: #{$baseExpandedWidth}px;
    min-width: #{$baseExpandedWidth}px;
    height: calc(#{$baseExpandedWidth}px + 1em);
    background-size: #{$baseExpandedWidth}px auto;

    > :last-child {
      $maximumNumberOfLinesOfText: 1em;
      $minimumNumberOfLinesOfText: 1em;
      $paddingSum: $basePadding * 2;
      $oneLineHeight: 1.7em;

      padding: $basePadding $basePadding;
      height: calc($paddingSum + $oneLineHeight);
      > * {
        height: $oneLineHeight;
        overflow: hidden;
      }
    }
  }
}

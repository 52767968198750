@import '../../../components/GalleryCard/GalleryCard.variables.scss';
@import '../../../../../node_modules/@onepercentio/one-ui/dist/assets/styles/variables.scss';

.collectionItem {
  margin-right: #{$baseAssetMargin}px;
  width: #{$baseWidthPxUnits}px;
  min-width: #{$baseWidthPxUnits}px;
}

.collectionContainer {
  --adaptive-container-transition-time: 250ms;
  width: 100%;
  * {
    text-align: initial;
  }
  > * {
    align-items: flex-start !important;
    flex: 1;
    > * {
      max-width: 100%;
    }
    > :first-child {
      opacity: 1 !important;
    }
    > :last-child {
      animation-delay: -200ms;
    }
  }
}
.fullList {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0px;
}
.container > button {
  margin: auto;
}

.root {
  margin-top: 48px;
  .sectionRoot {
    animation-name: fade-in;
    animation-duration: $fast;
    animation-timing-function: ease-in;
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.thumbnail {
  min-width: 1.5em;
  min-height: 1.5em;
  background-size: 1.5em;
  border-radius: 0.75em;
  margin-right: 8px;
}

@import './Home.module.scss';

.polkaBanner {
  width: 100%;
  max-height: 400px;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  > :last-child {
    z-index: 1;
    svg {
      max-height: 128px;
    }
  }
  img {
    position: absolute;
    width: 100%;
    object-fit: cover;
  }
}
